<template>
  <div
    v-click-outside="clickOutside"
    class="ui-fab"
    :class="[props.type]"
  >
    <div
      class="ui-fab__action"
      :class="{'ui-fab__action_dark': themeDark}"
      @click="toggle"
    >
      <img class="ui-fab__action__item" :src="`${staticUrl}/images/locale/${localeFromPath}.png`"
           :alt="`${localeFromPath}_flag`">
    </div>
    <div
      class="ui-fab__options"
      :class="[props.position, {open: isOpen},
       {'ui-fab__options_dark': themeDark}]"
    >
      <div
        v-for="option in props.options"
        :key="option.value"
        class="ui-fab__options-item"
        :class="[{'ui-fab__options-item_active' : localeFromPath === option.value},
        {'ui-fab__options-item_dark' : themeDark},
         {'ui-fab__options-item_active-dark' : themeDark && localeFromPath === option.value}]"
        @click="select(option.value)"
      >
        <img class="ui-fab__options-item__flag" :src="`${staticUrl}/images/locale/${option.value}.png`"
             :alt="`${option.value}_flag`">
        <span>{{ option.label }}</span>
      </div>
    </div>
    <LazyRussiaTooltip
      :position="positionRussianTooltip"
      v-if="showRussiaTooltip"
      @close="closeTooltip"
    />
  </div>
</template>

<script setup lang="ts">
import {useRoute} from 'vue-router';
import {ref, useRuntimeConfig} from '#imports';
import {getLocaleFromPath} from '~/helpers/getLocaleFromPath';

const runtimeConfig = useRuntimeConfig();
const staticUrl = runtimeConfig.public.vueAppStaticUrl;
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');
const route = useRoute();
const localeFromPath = computed(() => getLocaleFromPath(route.fullPath));

const emits = defineEmits(['select']);
const props = withDefaults(
  defineProps<{
    options: Array<{ label: string; value: string | number }>;
    position?: string;
    positionRussianTooltip?: string;
    type?: string;
    btnCloseColor?: string;
    themeDark?: boolean;
  }>(),
  {
    position: 'bottom',
    themeDark: false,
  },
);

const isOpen = ref(false);

const showRussiaTooltip = ref(false);

function select(value: string | number) {
  if (
    value === 'ru' &&
    !isDflat &&
    !route.fullPath.includes('offer')
  ) {
    showRussiaTooltip.value = true;
  }
  emits('select', value);
  toggle();
}

function closeTooltip(value: boolean) {
  showRussiaTooltip.value = value;
}

function clickOutside() {
  isOpen.value = false;
}

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped lang="scss">
.ui-fab {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  color: black;

  &__action {
    //cursor: pointer;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.2s;

    &:hover {
      transition: 0.2s;
      background: #E1E5F0;
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.4s, transform 0.4s;

      &::v-deep(*) {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    &-close {
      opacity: 0;
      transform: rotate(-180deg);

      svg {
        fill: #3d424a;
      }
    }

    &-icon {
      opacity: 1;
      //transform: rotate(0deg);
    }

    //&.open &-icon {
    //  opacity: 0;
    //  transform: rotate(180deg);
    //}

    //&.open &-close {
    //  opacity: 1;
    //  transform: rotate(0deg);
    //}
  }

  &.light &__action-close svg {
    fill: #fff;
  }

  &__options {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;
    background: var(--white-theme-white-200, #f6f8fd);
    border-radius: 16px;
    padding: 8px;
    gap: 4px;
    transform: scale(0.4) translateY(-62px);
    transform-origin: 50% 0;
    opacity: 0;
    pointer-events: none;
    transition: transform 0.18s ease-in, opacity 0.18s ease-in;
    box-shadow: 0px 0px 32px 0px rgba(24, 25, 26, 0.16), 0px 0px 16px 0px rgba(24, 25, 26, 0.12), 0px 0px 8px 0px rgba(24, 25, 26, 0.08);

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 16px;
      width: 150px;
      text-align: center;
    }

    &.open {
      opacity: 1;
      pointer-events: all;
      transform: scale(1) translate(0);
    }

    .dot {
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $primary;
      left: -8px;
      top: -8px;
    }

    &.left {
      right: 100%;
      top: 50%;
      transform: translate(-10px, -50%);

      .dot {
        left: 100%;
        top: 50%;
        transform: translate(-4px, -50%) rotate(90deg);
      }
    }

    &.right {
      left: 100%;
      top: 50%;
      transform: translate(10px, -50%);

      .dot {
        right: 100%;
        top: 50%;
        transform: translate(-4px, -50%) rotate(270deg);
      }
    }

    &.top {
      bottom: 100%;
      transform: translate(40%, -10px);

      .dot {
        top: 100%;
        left: 50%;
        transform: translate(-100%, 0px) rotate(180deg);
      }
    }

    &.bottom {
      top: 100%;
      right: 0;
      transform: translate(0, 10px);

      .dot {
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0) rotate(0deg);
      }
    }

    &.bottom-left {
      top: 100%;
      left: 0;
      transform: translate(-60%, 20px);

      .dot {
        bottom: 100%;
        left: 79%;
        transform: translate(-50%, 0) rotate(0deg);
      }
    }

    &.bottom-right {
      top: 100%;
      left: 0%;
      transform: translate(3.5%, 20px);

      .dot {
        bottom: 100%;
        left: 15%;
        transform: translate(-50%, 0) rotate(0deg);
      }
    }
  }
}

.ui-fab__action_dark {
  &:hover {
    background: #364E65;
  }
}

.ui-fab__options-item {
  border-radius: 8px;

  &:hover {
    background: #E1E5F0;
  }
}

.ui-fab__options-item_dark {
  &:hover {
    background: #7F97AA;
  }
}

.ui-fab__options_dark {
  background: #364E65;
  color: white;
}

.ui-fab__options-item_active {
  background: none;
  border: 1px solid #E1E5F0;
  &:hover {
    background: none;
  }
}

.ui-fab__options-item_active-dark {
  border: 1px solid #7F97AA;
}

.ui-fab__options-item__flag {
  max-width: 20px;
}

.ui-fab__action__item {
  width: 20px;
  height: auto;
  position: initial;
}
</style>
